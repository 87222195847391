<template>
  <div class="full-screen ltr-type">
    <div class="top-zone">
      <div class="tv zone1">Zone 1</div>
      <div class="zone23">
        <div class="tv zone2">Zone 2</div>
        <div class="tv zone3">Zone 3</div>
      </div>
    </div>
    <div class="tv zone4">Zone 4</div>
  </div>
</template>

<script>
export default {
  name: 'Tv1',
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
}
.tv {
  border: 10px solid #dfe0e3;
}
.top-zone {
  height: 80%;
  display: flex;
  .zone1 {
    width: 70%;
    background-color: #fcb340;
  }
  .zone23 {
    width: 30%;
    .zone2 {
      width: 100%;
      height: 50%;
      background-color: #0cbb3a;
    }
    .zone3 {
      width: 100%;
      height: 50%;
      background-color: #29abe2;
    }
  }
}

.zone4 {
  width: 100%;
  height: 20%;
  background-color: #f93959;
}
</style>